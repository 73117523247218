import React, { FC, Suspense, useCallback, useEffect, useMemo } from 'react';
import { useStore } from '@/store';
import ScrollRestoration from '@components/ScrollRestoration';
import AppRoutes from '@routes/AppRoutes';
import 'styles/global.scss';
import { ConfigProvider } from 'antd';
import vi_VN from 'antd/locale/vi_VN';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import SplashScreen from '@components/SplashScreen';
import { observer } from 'mobx-react-lite';
import ErrorBoundaries from '@components/ErrorBoundaries/ErrorBoundaries';
import { getAccountPermissions, getCurrentUser, getServices } from '@features/auth/services';
import { getPath } from '@routes/router-paths';
/** Slick */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

dayjs.locale('vi');
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.updateLocale('vi', {
  weekStart: 1,
});

/** Expose dayjs to window object for logging purpose */
// @ts-ignore
window.dayjs = dayjs;

const App: FC = () => {
  const {
    commonStore: { appTheme },
    accountInformationStore: { accountInformation, setAccountInformation, setMyPermission },
    serviceDataStore: { setPartnerServices },
  } = useStore();

  const handleGetCurrentUser = useCallback(async () => {
    const response = await getCurrentUser();
    setAccountInformation(response);
  }, [setAccountInformation]);

  const getPermissions = useCallback(async () => {
    const response = await getAccountPermissions();
    setMyPermission(response);
  }, [setMyPermission]);

  const handleGetServices = useCallback(
    async (partnerCode: string) => {
      const response = await getServices(partnerCode);
      setPartnerServices(response);
    },
    [setPartnerServices]
  );

  const shouldCheckUserIdentity = useMemo(
    () => localStorage.getItem('accessToken') && window.location.pathname !== getPath('login'),
    []
  );

  useEffect(() => {
    if (shouldCheckUserIdentity) {
      void handleGetCurrentUser();
    }
  }, [handleGetCurrentUser, shouldCheckUserIdentity]);

  useEffect(() => {
    const { partnerCode } = accountInformation;
    if (partnerCode) {
      void handleGetServices(partnerCode);
    }
  }, [accountInformation, handleGetServices]);

  useEffect(() => {
    if (!shouldCheckUserIdentity) return;
    void getPermissions();
  }, [getPermissions, shouldCheckUserIdentity]);

  return (
    <Suspense
      fallback={
        <ScrollRestoration>
          <SplashScreen fullscreen />
        </ScrollRestoration>
      }
    >
      <ConfigProvider
        locale={vi_VN}
        theme={{
          token: {
            colorPrimary: appTheme,
            borderRadius: 5,
          },
        }}
      >
        <ErrorBoundaries>
          <AppRoutes />
        </ErrorBoundaries>
      </ConfigProvider>
    </Suspense>
  );
};

export default observer(App);
