import React, { FC, memo, PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = PropsWithChildren & {
  title: string;
  subTitle: string;
};

const AuthLayout: FC<Props> = ({ children, title, subTitle }) => {
  return (
    <main
      className={clsx(
        'flex items-center justify-center',
        'min-h-screen px-10 w-full overflow-x-hidden'
      )}
      style={{
        background: 'var(--gradient-01)',
      }}
    >
      <section
        className="flex flex-col items-center bg-white rounded-xl px-10 py-16 w-[51rem]"
        style={{ boxShadow: '0px 8px 24px 0px #999BA840' }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/imgs/app-logo-full.svg`}
          alt="Logo"
          className="w-16 mb-6"
        />
        <h1 className="text-[3.6rem] text-default font-bold mb-2">{title}</h1>
        <p className="text-default-light text-sm mb-5">{subTitle}</p>
        {children}
      </section>
    </main>
  );
};

export default memo(AuthLayout);
